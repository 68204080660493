<template>
  <div class="assets">
    <div class="assets-tab row-center text-main">
      <div
        v-for="(item, index) in tabbar"
        :key="index"
        class="pointer"
        :class="tabbarIndex == index ? 'tabbarClass' : ''"
        @click="tabbarClick(index)"
      >
        {{ item }}
      </div>
    </div>
    <!-- 资产信息 -->
    <div class="assets-center" v-if="tabbarIndex == 0">
      <div class="space-between center-title">
        <div class="text-main">个人账户资产</div>
        <div class="text-main font-weight-normal">
          需要购买点数资产或套餐？您可以联系招聘顾问购买：<span
            class="text-blue pointer"
            @click="linkConsuClick"
            >联系顾问</span
          >
        </div>
      </div>
      <div class="space-between center-item">
        <div class="item-model">
          <div class="model-center" @scroll="scrollCoin">
            <div class="space-between model-title">
              <span class="text-main">纺织豆</span>
              <div class="bg-blue text-white flex-center pointer" @click="rechargeClick">
                充值
              </div>
            </div>
            <div class="model-num" v-if="accountObj">
              <div class="space-between num-item" v-if="accountObj.coin == 0">
                <span class="text-blue num-text">0</span>
                <span class="text-time"></span>
              </div>
              <div
                class="space-between num-item"
                v-for="(item, index) in coin_logs"
                :key="index"
              >
                <span class="text-blue num-text">{{ item.end_num }}</span>
                <span class="text-time">有效期：{{ item.end_time }}</span>
              </div>
            </div>
            <div v-else>暂无数据</div>
          </div>
        </div>
        <div class="item-model">
          <div class="model-center" @scroll="scrollPush">
            <div class="space-between model-title">
              <span class="text-main">人才推荐</span>
              <div class="bg-blue text-white flex-center pointer" @click="exchange(1)">
                兑换
              </div>
            </div>
            <div class="model-num" v-if="accountObj">
              <div class="space-between num-item" v-if="accountObj.push_num == 0">
                <span class="text-blue">0</span>
                <span class="text-time"></span>
              </div>
              <div
                class="space-between num-item"
                v-for="(item, index) in push_num_logs"
                :key="index"
              >
                <span class="text-blue">{{ item.end_num }}</span>
                <span class="text-time">有效期：{{ item.end_time }}</span>
              </div>
            </div>
            <div v-else>暂无数据</div>
          </div>
        </div>
        <div class="item-model">
          <div class="model-center" @scroll="scrollRecruit">
            <div class="space-between model-title">
              <span class="text-main">急招天数</span>
              <div class="bg-blue text-white flex-center pointer" @click="exchange(2)">
                兑换
              </div>
            </div>
            <div class="model-num" v-if="accountObj">
              <div class="space-between num-item" v-if="accountObj.recruit_num == 0">
                <span class="text-blue">0</span>
              </div>
              <div
                class="space-between num-item"
                v-for="(item, index) in recruit_num_logs"
                :key="index"
              >
                <span class="text-blue">{{ item.end_num }}</span>
                <span class="text-time">有效期：{{ item.end_time }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-model">
          <div class="model-center" @scroll="screenTopNum">
            <div class="space-between model-title">
              <span class="text-main">置顶天数</span>
              <div class="bg-blue text-white flex-center pointer" @click="exchange(3)">
                兑换
              </div>
            </div>
            <div class="model-num" v-if="accountObj">
              <div class="space-between num-item" v-if="accountObj.top_num == 0">
                <span class="text-blue">0</span>
              </div>
              <div
                class="space-between num-item"
                v-for="(item, index) in top_num_logs"
                :key="index"
              >
                <span class="text-blue">{{ item.end_num }}</span>
                <span class="text-time">有效期：{{ item.end_time }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-model">
          <div class="model-center" @scroll="scrollRefresh">
            <div class="space-between model-title">
              <span class="text-main">刷新次数</span>
              <div class="bg-blue text-white flex-center pointer" @click="exchange(4)">
                兑换
              </div>
            </div>
            <div class="model-num" v-if="accountObj">
              <div class="space-between num-item" v-if="accountObj.refresh_num == 0">
                <span class="text-blue">0</span>
              </div>
              <div
                class="space-between num-item"
                v-for="(item, index) in refresh_num_logs"
                :key="index"
              >
                <span class="text-blue">{{ item.end_num }}</span>
                <span class="text-time">有效期：{{ item.end_time }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单管理 -->
    <div class="order-manage" v-if="tabbarIndex == 1">
      <div class="manage-heard row-center text-main">
        <div @click="changeTab(1)" :class="{ current: currenIndex == 1 }" class="pointer">
          自助订单
        </div>
        <div @click="changeTab(2)" :class="{ current: currenIndex == 2 }" class="pointer">
          服务订单
        </div>
      </div>
      <div class="order-filter space-between">
        <div class="row-center filter-left">
          <div class="row-center time-filter">
            <span>生效时间</span>
            <div class="el-time-div">
              <el-date-picker
                v-model="query.create_time"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="row-center el-filter-category" v-if="currenIndex == 1">
            <span>资产类型</span>
            <div class="el-category">
              <el-select v-model="query.order_type" placeholder="请选择">
                <el-option
                  v-for="item in orderTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row-center el-filter-category" v-if="currenIndex == 1">
            <span>订单状态</span>
            <div class="el-category">
              <el-select v-model="query.pay_status" placeholder="请选择">
                <el-option
                  v-for="item in payStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row-center el-filter-category" v-if="currenIndex == 2">
            <span>合同状态</span>
            <div class="el-category">
              <el-select v-model="query.contract_status" placeholder="请选择">
                <el-option
                  v-for="item in contractList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div
          class="text-white bg-blue flex-center font-normal pointer"
          @click="orderSearchClick"
        >
          搜索
        </div>
      </div>
      <!-- table表 -->
      <div class="order-table">
        <div class="table-center">
          <el-table :data="orderArray" max-height="646" style="width: 100%">
            <el-table-column prop="pay_time" label="生效时间" width="180" align="center">
            </el-table-column>
            <el-table-column prop="order_no" label="订单编号" width="200" align="center">
            </el-table-column>
            <el-table-column prop="order_type" label="资产类型" align="center">
            </el-table-column>
            <el-table-column prop="num" label="资产数量" align="center">
            </el-table-column>
            <el-table-column prop="amount" label="支付金额" align="center">
            </el-table-column>
            <el-table-column prop="invoice_status" label="开具发票" align="center">
              <template slot-scope="scope">
                <div class="text-blue pointer" @click="applyClick(scope.row)">
                  {{
                    scope.row.invoice_status == 0
                      ? "申请"
                      : scope.row.invoice_status == 1
                      ? "未开发票"
                      : "已开发票"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="pay_status"
              label="订单状态"
              min-width="180"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.pay_status }} </span>
                <span
                  class="text-blue pointer"
                  @click="contractCLick(scope.row.order_no)"
                >
                  &nbsp;查看合同</span
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-div">
            <el-pagination
              :current-page="currentPage4"
              :page-sizes="[15, 30, 45, 60]"
              :page-size="15"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalOrder"
              @size-change="sizeChangeOrder"
              @current-change="currentChangeOrder"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 收支明细 -->
    <div class="order-manage" v-if="tabbarIndex == 2">
      <div class="order-filter space-between">
        <div class="row-center filter-left">
          <div class="row-center time-filter">
            <span>交易时间</span>
            <div class="el-time-div">
              <el-date-picker
                v-model="queryTwo.create_time"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="row-center el-filter-category">
            <span>交易类型</span>
            <div class="el-category">
              <el-select v-model="queryTwo.act_type" placeholder="请选择">
                <el-option
                  v-for="item in actTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row-center el-filter-category">
            <span>资产类型</span>
            <div class="el-category">
              <el-select v-model="queryTwo.account_type" placeholder="请选择">
                <el-option
                  v-for="item in accountTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div
          class="text-white bg-blue flex-center font-normal pointer"
          @click="actSearchClick"
        >
          搜索
        </div>
      </div>
      <!-- table表 -->
      <div class="order-table order-table-one">
        <div class="table-center">
          <el-table :data="accountArray" max-height="646" style="width: 100%">
            <el-table-column prop="order_no" label="交易编号" width="210" align="center">
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="交易时间"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="act_type" label="交易类型" align="center">
            </el-table-column>
            <el-table-column prop="type" label="资产类型" align="center">
            </el-table-column>
            <el-table-column prop="num" label="交易数量" align="center">
            </el-table-column>
            <el-table-column prop="user" label="操作人" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.creator == ''">{{
                  scope.row.user ? scope.row.user.user_name : ""
                }}</span>
                <span v-else>{{ scope.row.creator }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-div pagination-el-div">
            <el-pagination
              :current-page="currentPage4"
              :page-sizes="[15, 30, 45, 60]"
              :page-size="15"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalAcc"
              @size-change="sizeChangeAcc"
              @current-change="currentChangeAcc"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!--纺织豆充值组件  -->
    <recharge
      ref="recharge"
      :rechargeList="rechargeList"
      @hide="userAccount"
      @buyClick="buyClick"
      :exchangeObj="exchangeObj"
    ></recharge>
    <!-- 人才推荐组件 -->
    <recommend
      ref="recommend"
      @immediatelyPay="immediatelyPay"
      :pushList="pushList"
      :coin="coin"
      :exchangeObj="exchangeObj"
    ></recommend>
    <!-- 公共支付组件 -->
    <pay ref="payPrice" :payMent="payMent" @hide="userAccount"></pay>
    <!-- 兑换组件 -->
    <exchange
      ref="exchange"
      @immediatelyPay="immediatelyPay"
      :titleText="titleText"
      :numText="numText"
      :contentText="contentText"
      :exangeIndex="exangeIndex"
    ></exchange>
    <!-- 开具发票 -->
    <invoice
      ref="invoiceModel"
      :invoiceObj="invoiceObj"
      @addInvoice="addInvoice"
    ></invoice>
    <!-- 合同弹窗 -->
    <contract-model ref="contractModel" :resume_url="resume_url"></contract-model>
    <!-- 对公转账弹窗 -->
    <consultant ref="consultant"></consultant>
  </div>
</template>

<script>
import recharge from "./recharge.vue";
import recommend from "./recommend.vue";
import pay from "../../../components/pay.vue";
import exchange from "../../../components/exchange.vue";
import invoice from "../components/Invoice.vue";
import contractModel from "./contract.vue";
import api from "../../../api/user";
import toolApi from "../../../api/tool";
import entApi from "../../../api/enterprises";
import consultant from "../../../components/consultant.vue";
export default {
  components: {
    recharge,
    recommend,
    pay,
    exchange,
    invoice,
    contractModel,
    consultant,
  },

  data() {
    return {
      tabbar: ["资产信息", "订单管理", "收支明细"],
      tabbarIndex: 0,
      numText: "",
      titleText: "",
      contentText: "",
      currenIndex: 1,
      timeData: "",
      categoryValue: "",
      currentPage4: 1,
      // 资产相关的数据
      accountObj: null,
      rechargeList: [], //充值列表
      pushList: [], //推荐
      coin: 0,
      payMent: null,
      buyQuery: {
        type: "",
        num: "",
        pay_type: "",
      },
      exchangeQuery: {
        type: "",
        num: "",
      },
      exchangeObj: null,
      orderArray: [],
      accountArray: [],
      totalOrder: 1,
      totalAcc: 1,
      query: {
        page: 1,
        pageSize: 15,
        create_time: [],
        pay_status: "", //订单状态
        order_type: "", //资产类型
        contract_status: "", //合同状态
        type: 1,
      },
      queryTwo: {
        page: 1,
        pageSize: 15,
        create_time: [],
        account_type: "",
        act_type: "",
      },
      orderTypeList: [],
      payStatusList: [],
      contractList: [],
      actTypeList: [],
      accountTypeList: [],
      setTime: null,
      buyObj: null,
      invoiceObj: null,
      exangeIndex: 1,
      resume_url: "",
      coinShow: false,
      pushShow:false,
      recruitShow:false,
      topShow:false,
      refreshShow:false,
      top_num_logs: [],
      refresh_num_logs: [],
      recruit_num_logs: [],
      push_num_logs: [],
      coin_logs: [],
      actualQuery: {
        type: 1,
        pageSize: 10,
        page: 2,
      },
    };
  },

  created() {
    this.userAccount();
    this.getPlatFrom();
    this.configureClick();
  },
  beforeDestroy() {
    clearInterval(this.setTime);
  },
  mounted() {},

  methods: {
    actualAccounts(type) {
      this.actualQuery.type = type;
      api.actualAccounts(this.actualQuery).then((res) => {
        if (res.code == 200) {
          if (type == 1) {
            this.coin_logs =this.coin_logs.concat(res.data.data);
          } else if (type == 2) {
            this.refresh_num_logs = this.refresh_num_logs.concat(res.data.data);
          } else if (type == 3) {
            this.top_num_logs =this.top_num_logs.concat(res.data.data);
          } else if (type == 4) {
            this.recruit_num_logs =this.recruit_num_logs.concat(res.data.data);
          } else if (type == 5) {
            this.push_num_logs =this.push_num_logs.concat(res.data.data);
          }
        }
      });
    },
    scrollCoin(e) {
      var scrollTop = e.srcElement.scrollTop;
      var windowHeight = e.srcElement.clientHeight;
      var scrollHeight = e.srcElement.scrollHeight;
      if (this.coinShow) return;
      if (scrollTop + windowHeight == scrollHeight) {
        this.coinShow = true;
        setTimeout(() => {
          this.coinShow = false;
        }, 5000);
        if (this.actualQuery.type == 1) {
          this.actualQuery.page++;
        } else {
          this.actualQuery.page = 2;
        }
        this.actualAccounts(1);
      }
    },
    scrollRefresh(e) {
      var scrollTop = e.srcElement.scrollTop;
      var windowHeight = e.srcElement.clientHeight;
      var scrollHeight = e.srcElement.scrollHeight;
      if (this.refreshShow) return;
      if (scrollTop + windowHeight == scrollHeight) {
        this.refreshShow = true;
        setTimeout(() => {
          this.refreshShow = false;
        }, 5000);
        if (this.actualQuery.type == 2) {
          this.actualQuery.page++;
        } else {
          this.actualQuery.page = 2;
        }
        this.actualAccounts(2);
      }
    },
    screenTopNum(e) {
      var scrollTop = e.srcElement.scrollTop;
      var windowHeight = e.srcElement.clientHeight;
      var scrollHeight = e.srcElement.scrollHeight;
      if (this.topShow) return;
      if (scrollTop + windowHeight == scrollHeight) {
        this.topShow = true;
        setTimeout(() => {
          this.topShow = false;
        }, 5000);
        if (this.actualQuery.type == 3) {
          this.actualQuery.page++;
        } else {
          this.actualQuery.page = 2;
        }
        this.actualAccounts(3);
      }
    },
    scrollRecruit(e) {
      var scrollTop = e.srcElement.scrollTop;
      var windowHeight = e.srcElement.clientHeight;
      var scrollHeight = e.srcElement.scrollHeight;
      if (this.recruitShow) return;
      if (scrollTop + windowHeight == scrollHeight) {
        this.recruitShow = true;
        setTimeout(() => {
          this.recruitShow = false;
        }, 5000);
        if (this.actualQuery.type == 4) {
          this.actualQuery.page++;
        } else {
          this.actualQuery.page = 2;
        }
        this.actualAccounts(4);
      }
    },
    scrollPush(e) {
      var scrollTop = e.srcElement.scrollTop;
      var windowHeight = e.srcElement.clientHeight;
      var scrollHeight = e.srcElement.scrollHeight;
      if (this.pushShow) return;
      if (scrollTop + windowHeight == scrollHeight) {
        this.pushShow = true;
        setTimeout(() => {
          this.pushShow = false;
        }, 5000);
        if (this.actualQuery.type == 5) {
          this.actualQuery.page++;
        } else {
          this.actualQuery.page = 2;
        }
        this.actualAccounts(5);
      }
    },
    addInvoice() {
      this.orderList();
    },
    buyClick(obj) {
      clearInterval(this.setTime);
      this.buyObj = obj;
      this.setInter(obj);
    },
    // 轮训查看是否支付成功
    setInter() {
      this.setTime = setInterval(() => {
        api.searchOrder({ order_no: this.buyObj.order_no }).then((res) => {
          if (res.code == 200) {
            if (res.data.pay_status == 1) {
              this.$refs.recharge.hide();
              this.$refs.payPrice.hide();
              this.userAccount();
              clearInterval(this.setTime);
              this.$util.msg("支付成功");
            }
          }
        });
      }, 1000);
    },

    linkConsuClick() {
      this.$refs.consultant.show();
    },
    actSearchClick() {
      this.queryTwo.page = 1;
      this.queryTwo.pageSize = 15;
      this.currentPage4 = 1;
      this.accountLogs();
    },
    orderSearchClick() {
      this.query.page = 1;
      this.query.pageSize = 15;
      this.currentPage4 = 1;
      this.orderList();
    },
    // 获取配置
    configureClick() {
      toolApi.getUserConfig().then((res) => {
        if (res.code == 200) {
          res.data.order_type.options.unshift({
            value: "",
            label: "不限",
          });
          res.data.pay_status.options.unshift({
            value: "",
            label: "不限",
          });
          res.data.contract_status.options.unshift({
            value: "",
            label: "不限",
          });
          res.data.act_type.options.unshift({
            value: "",
            label: "全部",
          });
          res.data.account_type.options.unshift({
            value: "",
            label: "全部",
          });
          this.orderTypeList = res.data.order_type.options;
          this.payStatusList = res.data.pay_status.options;
          this.contractList = res.data.contract_status.options;
          this.actTypeList = res.data.act_type.options;
          this.accountTypeList = res.data.account_type.options;
        }
      });
    },
    sizeChangeOrder(val) {
      this.query.pageSize = val;
      this.orderList();
    },
    currentChangeOrder(val) {
      this.query.page = val;
      this.orderList();
    },
    sizeChangeAcc(val) {
      this.queryTwo.pageSize = val;
      this.accountLogs();
    },
    currentChangeAcc(val) {
      this.queryTwo.page = val;
      this.accountLogs();
    },
    // 订单列表
    orderList() {
      entApi.orderList(this.query).then((res) => {
        if (res.code == 200) {
          this.orderArray = res.data.data;
          this.totalOrder = res.data.total;
        }
      });
    },
    // 收支明细列表
    accountLogs() {
      entApi.accountLogs(this.queryTwo).then((res) => {
        if (res.code == 200) {
          this.accountArray = res.data.data;
          this.totalAcc = res.data.total;
        }
      });
    },
    // 兑换服务
    exChangeService() {
      api.exChangeService(this.exchangeQuery).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg);
          this.userAccount();
        }
      });
    },
    // 购买服务
    buyService() {
      api.buyService(this.buyQuery).then((res) => {
        clearInterval(this.setTime);
        this.buyObj = res.data;
        if (res.code == 200 && this.buyQuery.pay_type == 3) {
          let divForm = document.getElementsByTagName("divform");
          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = res.data.payment;
          document.body.appendChild(div);
          document.forms[0].setAttribute("target", "_blank");
          document.forms[0].submit();
        } else if (res.code == 200 && this.buyQuery.pay_type == 4) {
          this.payMent = res.data;
          this.$refs.payPrice.show();
        }
        this.setInter();
      });
    },
    // 获取用户资产
    userAccount() {
      api.userAccount().then((res) => {
        if (res.code == 200) {
          this.accountObj = res.data;
          this.coin = res.data.coin;
          this.coin_logs = res.data.coin_logs;
          this.push_num_logs = res.data.push_num_logs;
          this.recruit_num_logs = res.data.recruit_num_logs;
          this.refresh_num_logs = res.data.refresh_num_logs;
          this.top_num_logs = res.data.top_num_logs;
        }
      });
    },
    tabbarClick(index) {
      this.tabbarIndex = index;
      if (index == 0) {
        this.userAccount();
      } else if (index == 1) {
        this.orderList();
      } else {
        this.accountLogs();
      }
    },
    getPlatFrom() {
      api.getPlatFrom().then((res) => {
        if (res.code == 200) {
          this.rechargeList = res.data.recharge;
          this.pushList = res.data.push;
          this.exchangeObj = res.data.exchange;
        }
      });
    },
    // 充值
    rechargeClick() {
      this.$refs.recharge.show();
    },
    // 兑换
    exchange(index) {
      this.exangeIndex = index;
      if (index == 1) {
        this.$refs.recommend.show();
      } else if (index == 2) {
        this.exchangeQuery.type = "recruit";
        this.buyQuery.type = "recruit";
        this.$refs.exchange.show();
        this.numText = "天";
        this.titleText = "急招";
        this.contentText =
          "【急招职位区】位于首页急招专区，提升专项曝光度，人才更快就位。";
      } else if (index == 3) {
        this.exchangeQuery.type = "top";
        this.buyQuery.type = "top";
        this.$refs.exchange.show();
        this.numText = "天";
        this.titleText = "置顶";
        this.contentText =
          "【置顶职位区】位于首页置顶区，获得顶级曝光，让更多人才快速发 现您的职位！";
      } else {
        this.exchangeQuery.type = "refresh";
        this.buyQuery.type = "refresh";
        this.$refs.exchange.show();
        this.numText = "次";
        this.titleText = "刷新";
        this.contentText =
          "【刷新职位区】位于置顶职位下方，其他未刷新职位上方。排名越靠前，可获得更多在求职者面前的曝光机会。";
      }
    },
    // 人才推荐立即支付
    immediatelyPay(item) {
      if (Object.keys(item).length == 3) {
        if (item.pay_type !== 1) {
          this.buyQuery = item;
          this.buyService();
        } else {
          this.exchangeQuery.type = item.type;
          this.exchangeQuery.num = item.num;
          this.exChangeService();
        }
      } else {
        if (item.pay_type !== 1) {
          this.buyQuery.num = item.num;
          this.buyQuery.pay_type = item.pay_type;
          this.buyService();
        } else {
          this.exchangeQuery.num = item.num;
          this.exChangeService();
        }
      }
    },
    // 切换订单管理
    changeTab(index) {
      this.currenIndex = index;
      this.$util.objClear(this.query);
      this.query.type = index;
      this.orderList();
    },
    // 申请发票以及查看进度
    applyClick(obj) {
      if (obj.invoice_status == 0) {
        this.invoiceObj = obj;
        this.$refs.invoiceModel.show();
      } else {
        // 判断发票是否已经开了
        // 发票未开   发票已开
        // error  success
        let context;
        let type;
        if (obj.invoice_status == 1) {
          context = "发票未开";
          type = "error";
        } else {
          context = "发票已开";
          type = "success";
        }
        this.$confirm(context, {
          confirmButtonText: "确定",
          showCancelButton: false,
          type,
        });
      }
    },
    // 查看合同
    contractCLick(order_no) {
      toolApi.getContract(order_no).then((res) => {
        if (res.code == 200) {
          this.resume_url = res.data.resume_url;
          this.$refs.contractModel.show();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.assets {
  width: 100%;
  padding: 1.8125rem 9.1875rem 0 9.0625rem;
  .assets-tab {
    font-size: 1.25rem;
    div {
      margin-right: 2.4375rem;
    }
    .tabbarClass {
      font-size: 1.5rem;
      color: #126bf9;
    }
  }
  // 资产信息css
  .assets-center {
    width: 100%;
    background: #ffffff;
    margin-top: 1.25rem;
    box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
    padding: 2rem 2.4375rem 2rem 2.5rem;
    .center-title {
      font-weight: bold;
      font-size: 1.125rem;
      margin-bottom: 1.75rem;
      div:last-child {
        font-size: 0.9375rem;
      }
    }
    .center-item {
      flex-wrap: wrap;
      width: 100%;
      .item-model {
        width: 40.8rem;
        margin-bottom: 1.375rem;
        padding: 8px 10px 11px 9px;
        .model-center {
          width: 100%;
          height: 12.3125rem;
          padding: 1.8125rem 1.875rem 2.375rem 1.8125rem;
          background: #ffffff;
          box-shadow: 0px 1px 6px 2px rgba(221, 221, 221, 0.69);
          border-radius: 0.5625rem;
          overflow: auto;
          .model-title {
            margin-bottom: 2.0625rem;
            font-size: 1.0625rem;
            div {
              font-size: 0.875rem;
              width: 3.6875rem;
              height: 1.8125rem;
              background: #126bf9;
              border-radius: 5px;
            }
          }
          .model-num {
            font-size: 30px;
            .num-item {
              .num-text {
                color: #fc7a4c;
              }
              .text-time {
                color: #757575;
                font-size: 0.875rem;
              }
            }
            .num-item:last-child {
              margin-bottom: 0;
            }
          }
        }
        .model-center::-webkit-scrollbar-thumb {
          display: none !important;
        }
      }
    }
  }
  // 订单管理
  .order-manage {
    margin-top: 1.375rem;
    .manage-heard {
      width: 100%;
      height: 2.4375rem;
      background: #ffffff;
      margin-bottom: 0.625rem;
      padding-left: 2.3125rem;
      font-size: 0.9375rem;
      div {
        height: 100%;
        line-height: 2.4375rem;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-bottom: 6px solid #126bf9;
          left: 0;
          bottom: 0;
          transition: 0.3s;
        }
      }
      div:last-child {
        margin-left: 3.625rem;
      }
      .current {
        color: #126bf9;

        &:after {
          width: 3.75rem;
        }
      }
    }
    .order-filter {
      height: 3.875rem;
      background: #ffffff;
      padding: 0 2.3125rem;
      margin-bottom: 0.625rem;
      > div:last-child {
        width: 4.875rem;
        height: 1.75rem;
        border-radius: 2px;
      }
      .time-filter {
        font-size: 13px;
        color: #555555;
        .el-time-div {
          margin-left: 11px;
          width: 230px;
          /deep/.el-input__inner {
            width: 230px;
          }
        }
      }
      .el-filter-category {
        font-size: 13px;
        color: #555555;
        margin-left: 40px;
        .el-category {
          margin-left: 10px;
          width: 156px;
        }
      }
    }
    .order-table {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
      height: 44.75rem;
      padding: 1.25rem 2.3125rem 2.0625rem 2.25rem;
      .table-center {
        // border: 1px solid #f2f2f2;
        width: 100%;
        height: 100%;
        position: relative;
        /deep/.el-table--fit {
          max-width: 82.6875rem;
          height: 40.375rem !important;
        }
        /deep/.el-table__body-wrapper {
          max-height: calc(40.375rem - 48px) !important;
        }
        /deep/.el-table th.el-table__cell {
          background: #f9f9f9;
        }
      }
    }
    .order-table-one {
      height: 47rem;
    }

    .pagination-div {
      margin-top: 0.625rem;
    }
    .pagination-el-div {
      margin-top: 2rem;
    }
  }
}
</style>
